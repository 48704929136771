<template>
  <div class="filter-lookalike">
    <component
      :is="computedComponentName"
      v-model="shouldShowModal"
      :close-on-content-click="false"
    >
      <template v-slot:activator="{ on, attrs }">
        <div
          class="d-flex align-center justify-between filter-title"
          :class="{ 'font-weight-bold': isFilterApplied }"
          v-on="on"
          v-bind="attrs"
          >
          <div class="d-flex align-center flex-grow-1">
            <v-icon left>
              supervised_user_circle
            </v-icon>
            Lookalikes
          </div>
          <v-icon>
            expand_more
          </v-icon>
        </div>
      </template>
      <v-card>
        <v-card-title class="d-md-none">
          Lookalikes
        </v-card-title>

        <v-card-text
          class="pt-md-3"
          :class="{ 'black--text': isThemeLight }"
        >
          <v-row>
            <!-- Audience Section -->
            <v-col
              v-for="kind in ['audience', 'influencer']"
              :key="kind"
              cols="12"
              md="6"
            >
              <div class="mb-1 d-flex">
                <div class="d-flex align-center flex-grow-1">
                  <v-icon
                    :class="{ 'black--text': isThemeLight }"
                    class="mr-1"
                    left
                  >
                    {{ kind === 'audience' ? 'people' : 'volume_down' }}
                  </v-icon>

                  {{ capitalizeString(kind) }}
                </div>

                <v-tooltip bottom>
                  <template v-slot:activator="{ on, attrs }">
                    <v-icon
                      :color="isThemeLight ? 'black' : null"
                      v-bind="attrs"
                      v-on="on"
                    >
                      info
                    </v-icon>
                  </template>

                  <span>
                    {{ tooltipTexts[kind] }}
                  </span>
                </v-tooltip>
              </div>

              <profile-selector
                @change="(v) => submitForm(kind, v)"
                label="Lookalikes"
                :platform="platform"
                :type="kind === 'audience' ? 'lookalike' : 'topic-tags'"
                list-item-class="filter-lookalike-list-item"
                outlined
                dense
              />

              <div v-if="appliedFiltersFor[kind].length" class="mt-3">
                <div
                  v-for="item in appliedFiltersFor[kind]"
                  :key="item.id"
                >
                  <div class="d-flex justify-space-between align-center pb-1 pt-3">
                    @{{ item.data.inputs.nameToShow }}

                    <v-btn icon color="red" @click="removeFilterItem(item)">
                      <v-icon>
                        delete
                      </v-icon>
                    </v-btn>
                  </div>
                </div>
              </div>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </component>
  </div>
</template>

<script>
import { required } from "vuelidate/lib/validators"
const ProfileSelector = () => import(/* webpackChunkName: "profile-selector" */ "@/blocks/common/selectors/ProfileSelector.vue")

// used throughout the code
const filterName = "lookAlike"

const originalForm = () => ({
  audience: null,
  influencer: null
})

export default {
  name: "FilterLookAlike",

  props: {
    platform: {
      type: String,
      required: true
    }
  },

  components: {
    ProfileSelector
  },

  data: () => ({
    form: originalForm(),

    shouldShowModal: false,

    tooltipTexts: {
      audience: "Audience lookalikes help you find influencers that have similar audience.",
      influencer: "Based on TopicTensor® technology, help you find influencers that post similar content."
    }
  }),

  computed: {
    // show the filter as a dialog for mobile dialogs
    computedComponentName() {
      return this.isDesktopDevice ? "v-menu" : "v-dialog"
    },

    // get a list of all of them
    appliedFilters() {
      return this.$store.getters["influencerDiscovery/findFiltersByType"](filterName)
    },

    appliedFiltersFor() {
      return {
        audience: this.appliedFilters.filter((item) => item.data.kind === "audience"),
        influencer: this.appliedFilters.filter((item) => item.data.kind === "influencer")
      }
    },

    // Used to bold the filter name text
    isFilterApplied() {
      return Boolean(this.appliedFilters.length)
    }
  },

  validations: {
    form: {
      audience: {
        required
      },
      influencer: {
        required
      }
    }
  },

  methods: {
    /**
     * Takes an item and generates the text based on kind and weight
     */
    computeFilterText({ kind, name }) {
      return `${capitalizeString(kind)} Lookalike: ${name}`
    },

    /**
     * Push the data from either of the inputs to the filters array in Vuex Store
     *
     * @param {String} kind | Either "audience" or "influencer"
     */
    async submitForm(kind, value) {
      this.form[kind] = value

      await this.$v.form[kind].$touch()

      if (this.$v.form[kind].$anyError) return

      const method = (kind === "audience") ? "replaceFilter" : "addFilter"

      this.$store.dispatch(`influencerDiscovery/${method}`, {
        type: filterName,
        data: {
          color: "purple lighten-5",
          icon: "supervised_user_circle",
          iconColor: "purple",
          kind,
          text: this.computeFilterText({ kind, name: this.form[kind].nameToShow }),
          inputs: {
            ...this.form[kind]
          }
        }
      })

      // reset the form input to allow more values
      this.form[kind] = null
      // finally hide the modal
      this.shouldShowModal = false
    },

    /**
     * Delete the entry from vuex store
     *
     * @param {Object} item
     */
    removeFilterItem(item) {
      this.$store.dispatch("influencerDiscovery/removeFilter", item.id)
    },
  },
}
</script>

<style lang="stylus">
@media (min-width 960px)
  .filter-lookalike-list-item
    width 320px !important
</style>
